<template>
  <div>
    <van-button class="button" plain type="info" @click="goRemindDetail()" square>添加</van-button>

    <van-swipe-cell v-for="(item) in remindList" :key="item.Id">
      <div class="item-safe-area" @click="goRemindDetail(item)">
        <div class="contr-left">
          <img class="img-left" src="../assets/event_reminder_false.png" alt="">

          <div class="contr-content">
            {{ getRemindTitle(item.EType) }}
            <div style="margin-top: 2px">
              <span class="text-time">{{ item.ETime }}</span>
              <span class="text-type">{{ getRemindType(item.EWeek) }}</span>
            </div>
          </div>
        </div>

        <img class="img-right" src="../assets/app_arrow_right.png" alt="">
      </div>


      <template #right>
        <van-button square text="删除" type="danger" class="delete-button" @click="deleteRemind(item)"/>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
import {Toast} from "vant";
import {post} from "@/router/httpUtils";
import {OrderSet_SendSuccess, State_0,} from "@/constants/contants";
import {Language} from "@/constants/contants";
import {getRemindTitle, getRemindType} from "@/util/remindUtil";
import Vue from 'vue';
import {SwipeCell} from 'vant';

Vue.use(SwipeCell);


export default {
  name: "NotifyList",
  data() {
    return {
      remindList: [
        /*{
      "Id": Number,
      "Long": Number,
      "DeviceId": Number,
      "EIndex": Number,
      "Interval": Number,
      "EType": Number,
      "EWeek": String,
      "EContent": String,
      "ETime": String,
      "IsVoice": Boolean,
      "VoiceUrl": String,
      "IdentityID": String
    }*/
      ],

    }
  },
  mounted() {
    this.getRemindList()
  },
  methods: {
    goRemindDetail(item) {
      console.log(item)
      this.$router.push({
        name: 'RemindSetting',
        params: item
      })
    },
    getRemindList() {
      post('/api/Command/EventRemindList', {
        "DeviceId": localStorage.DeviceId,
        "Language": Language,
        "Token": localStorage.Token
      }).then(res => {
        console.log(res)

        if (res.State != State_0) {
          Toast.fail({
            message: '网络异常'
          })
          return
        }

        this.remindList = res.Items
      })
      // .catch(err => {
        // Toast.fail({
        //   message: err
        // })
      // })
    },
    deleteRemind(item) {
      post('/api/Command/DeleteEventRemind', item)
          .then(res => {
            console.log(res)
            Toast.success({
              message: OrderSet_SendSuccess
            })
            this.getRemindList()
          })
          // .catch(err => {
            // Toast.fail({
            //   message: err
            // })
          // })
    },
    getRemindTitle(type) {
      return getRemindTitle(type);
    },
    getRemindType(EWeek) {
      return getRemindType(EWeek)
    },
  }
}
</script>
<style scoped>
.button {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.item-safe-area {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid gray;
  padding: 10px 15px;
}

.text-type {
  margin-left: 14px;
  font-size: 12px;
}

.text-time {
  font-size: 17px;
}

.delete-button {
  height: 100%;
}

.contr-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.contr-content {
  height: fit-content;
  margin-left: 10px;
}

.img-right {
  width: 20px;
  height: 20px;
}

.img-left {
  width: 40px;
  height: 40px;
}
</style>