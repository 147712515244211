export function getRemindTitle(type) {
    let result = ''
    switch (type) {
        case 0:
            result = '吃药提醒'
            break;
        case 1:
            result = '喝水提醒'
            break;
        case 2:
            result = '运动提醒'
            break;
        case 3:
            result = '自定义1'
            break;
        case 4:
            result = '自定义2'
            break;
        case 5:
            result = '自定义3'
            break;
    }
    return result;
}

export function getRemindType(EWeek) {
    if (!EWeek) return ''

    return Array.from(EWeek).map((t, index) => {
        return t === '1' ? '一二三四五六日'.charAt(index) : ''
    }).filter(t => t).join(',')
}